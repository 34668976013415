import MainLayout from '@/Layouts/MainLayout';
import { Link, Head, useRemember } from '@inertiajs/react';
import LetterList from '@/Components/LetterList';
import Paginator from '@/Components/Paginator';

export default function More({ auth, letters }) {

    const [paginationData, setPaginationData] = useRemember(letters);

    return (
        <>
            <MainLayout auth={auth}>
                <Head title="liham ng puso" />
                <LetterList letters={letters} level="0" />
                <Paginator paginationData={paginationData} />
            </MainLayout>
        </>
    );
}
